import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const ICON_ID = 'cockade';
const namespace = 'ui-pdp-icon';

const IconCockade = ({ className }) => <Image src="cockade.svg" alt="" className={classnames(namespace, className)} />;

IconCockade.propTypes = {
  className: string,
};

IconCockade.defaultProps = {
  className: null,
};

IconCockade.ICON_ID = ICON_ID;

export default React.memo(IconCockade);
export { IconCockade };
